<template>
  <div class="performance-report-financial-security-and-security">

    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>
      <b-container class="main-gradient-header-wrapper">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="breadcrumb-light"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" md="9" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title_financial_security') }}</h1>
            </b-col>
            <b-col cols="6" md="3" class="pull-right pull-md-left pr-0 pl-0 pl-md-2">
              <period-selector
                default-period-type="last-year"
                :show-predefined-periods="true"
                :show-resolution="true"
                :allowed-resolutions="['month', 'year']"
                :default-resolution="resolution"
                @period-selected="applyFilter"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-4" no-gutters>
          <b-col no-gutters>
            <financial-security-submenu ref="Tabmenu" :translationPath="this.translationPath" @tab-switched="onTabSwitched" :active="this.activeTab" :currency-symbol="currencySymbol"></financial-security-submenu>
          </b-col>
        </b-row>

      </b-container>

      <b-row class="main-gradient-chart-wrapper">
        <div class="w-100 pb-5" style="height:500px;">
          <financial-security-percentage-chart :apiData="apiData" v-if="this.activeTab === 'percentage'" :busy="busyState.chart"></financial-security-percentage-chart>
          <financial-security-percentage-acc-chart :apiData="apiData" v-if="this.activeTab === 'percentage-accumulated'" :busy="busyState.chart"></financial-security-percentage-acc-chart>
          <financial-security-amount-chart :apiData="apiData" :currency-symbol="currencySymbol" v-if="this.activeTab === 'amounts'" :busy="busyState.chart"></financial-security-amount-chart>
          <financial-security-amount-acc-chart :apiData="apiData" :currency-symbol="currencySymbol" v-if="this.activeTab === 'amounts-accumulated'" :busy="busyState.chart"></financial-security-amount-acc-chart>
        </div>
      </b-row>

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0">
      <financial-security-data-table :apiData="apiData" :busy="busyState.table"></financial-security-data-table>
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import FinancialSecuritySubmenu from './FinancialSecuritySubmenu'
import FinancialSecurityPercentageChart from './FinancialSecurityPercentageChart'
import FinancialSecurityPercentageAccChart from './FinancialSecurityPercentageAccChart'
import FinancialSecurityAmountChart from './FinancialSecurityAmountChart'
import FinancialSecurityAmountAccChart from './FinancialSecurityAmountAccChart'
import FinancialSecurityDataTable from './FinancialSecurityDataTable'
import PeriodSelector from '@/components/common/PeriodSelector'

export default {
  name: 'FinancialSecurity',
  mixins: [titleMixins],
  components: {
    FinancialSecurityPercentageChart,
    FinancialSecurityPercentageAccChart,
    FinancialSecurityDataTable,
    FinancialSecuritySubmenu,
    FinancialSecurityAmountChart,
    FinancialSecurityAmountAccChart,
    PeriodSelector
  },
  data () {
    return {
      // start_date: '2010-01-01',
      start_date: new Date(new Date().setMonth(new Date().getMonth() - 12)).toISOString().slice(0, 8).toString() + '01', // First day of this month a year ago
      end_date: new Date().toISOString().slice(0, 10), // Todays date
      resolution: 'month',
      apiData: [],
      activeTab: 'percentage',
      busyState: {
        chart: true,
        table: true
      },
      translationPath: 'reports.performance.financial_security.'
    }
  },
  mounted () {
    this.changeTab(this.$route.params.tab)
    this.$bvToast.toast(this.$t('common.toasts.loading_data'), {
      title: this.$t('reports.performance.financial_security.title'),
      variant: 'info',
      solid: true
    })
    this.fetchApiData()
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.performance_reports'), to: '/reports/performance' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t('reports.performance.financial_security.financial_security')
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    applyFilter (eventData) {
      this.resolution = eventData.resolution
      this.start_date = eventData.sDateStart
      this.end_date = eventData.sDateEnd
      this.fetchApiData()
    },
    fetchApiData () {
      this.busyState.chart = true
      this.busyState.table = true

      // http://api.spirecta.test/api/v1/reports/performanceRatios?start_date=2010-01-01&resolution=week
      const ApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/performanceRatios?start_date=${this.start_date}&end_date=${this.end_date}&resolution=${this.resolution}`
      return axios.get(ApiEndpoint)
        .then(response => response.data.data)
        .then(responseData => {
          this.apiData = Object.prototype.hasOwnProperty.call(responseData, 'ratios') ? responseData.ratios : []
          this.busyState.chart = false
          this.busyState.table = false
          return true
        })
        .catch(err => {
          console.error(err)
          return false
        })
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: newtab } })
      }
    },
    changeTab (tab) {
      if (['percentage', 'percentage-accumulated', 'amounts', 'amounts-accumulated'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
        this.setPageTitle(this.titleMeta)
      }
    }
  },
  watch: {
    start_date: function (newVal, oldVal) {
      if (this.showToastOnYearChange) {
        this.$bvToast.toast(this.$t('common.toasts.loading_data_for_period', { start_date: this.start_date, end_date: this.end_date }), {
          title: this.$t('reports.performance.financial_security.title'),
          variant: 'info',
          solid: true
        })
      } else {
        this.showToastOnYearChange = true
      }

      if (newVal) {
        this.fetchApiData().then(status => {
          this.$bvToast.toast(this.$t('common.toasts.data_for_period_is_now_loaded', { start_date: this.start_date, end_date: this.end_date }), {
            title: this.$t('reports.performance._common.financial_security'),
            variant: 'success',
            solid: true
          })
        })
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    }
  }
}
</script>

<style lang="scss">
  .performance-report-financial-security-and-security
  {
    width: 100%;
  }
</style>
