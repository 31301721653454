<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="percentage" :class="{selected: activeTab === 'percentage'}"  @click="onTabClick">
          {{ $t(translationPath + 'financialSecurityPercentageTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="percentage-accumulated" :class="{selected: activeTab === 'percentage-accumulated'}"  @click="onTabClick">
          {{ $t(translationPath + 'financialSecurityPercentageAccTab') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="amounts" :class="{selected: activeTab === 'amounts'}"  @click="onTabClick">
          {{ $t( translationPath + 'financialSecurityAmountsTab', {currency: currencySymbol}) }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="amounts-accumulated" :class="{selected: activeTab === 'amounts-accumulated'}"  @click="onTabClick">
          {{ $t( translationPath + 'financialSecurityAmountsAccTab', {currency: currencySymbol}) }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    translationPath: {},
    active: {
      type: String,
      default: 'percentage'
    },
    currencySymbol: {
      type: String
    }
  },
  name: 'SavingsSubmenu',
  data () {
    return {
      activeTab: null
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
